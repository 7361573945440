export const TESTING_STATUSES = {
  NOT_SEND: 'NOT_SEND',
  NOT_STARTED: 'NOT_STARTED',
  IN_PROGRESS: 'IN_PROGRESS',
  NOT_COMPLETED: 'NOT_COMPLETED',
  COMPLETED: 'COMPLETED',
};
export const TESTING_STATUSES_TITLES = {
  IN_PROGRESS: 'В процессе',
  NOT_COMPLETED: 'Не завершено',
  NOT_STARTED: 'Не начато',
  NOT_SEND: 'Не отправлено',
  COMPLETED: 'Завершено',
};
