<template>
  <div class="auth-card large android text-center text-primary">
    <h2 class="auth-card__title mb-8">{{testData.title}}</h2>

    <div class="android" />

    <div v-if="testData.first_paragraph" v-html="text1" class="mb-8"></div>

    <div v-if="testData.timer" class="block-timer font-weight-bold mb-8">
      Действует таймер <v-icon color="#25325F">mdi-alarm</v-icon>
      <br>
      На одно задание - {{testData.timer}} секунд
    </div>

    <div v-if="testData.second_paragraph" v-html="text2" class="mb-12"></div>

    <v-btn class="col-md-6" @click="nextStep({ token: $route.params.token })"
           x-large color="primary">
      Перейти к тесту
    </v-btn>

  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'Start',
  computed: {
    ...mapGetters({
      testData: 'testing/testing/testData',
    }),
    text1() {
      return this.testData.first_paragraph.replace(/\n/g, '<br>');
    },
    text2() {
      return this.testData.second_paragraph.replace(/\n/g, '<br>');
    }
  },
  methods: mapActions({
    nextStep: 'testing/testing/nextStep',
  }),
};
</script>
<style lang="scss" scoped>
@import "src/assets/styles/variables";
.block-timer {
  display: inline-block;
  background: $color-very-light-gray;
  width: auto;
  padding: 1.5rem 3rem;
}
</style>
