/* eslint-disable */

import { CANDIDATE } from "@/common/constants/userTypes";

const allTabs =
[
  { title: 'Отчет соответствия',       component: 'ComplianceReport',         permission: 'view_testresultscompliencereport', checkField: 'professiograms' },
  { title: 'Риски',                    component: 'Risks',                    permission: 'view_testresultsrisks',            checkField: 'risk_factors', /*hide_minimult: true */},
  { title: 'Шкалы',                    component: 'Scales',                   permission: 'view_testresultsscales',           checkField: 'scales'},
  { title: 'Достоверность',            component: 'Credibility',              permission: 'view_testresultsreliability',      checkField: 'credibility', /*hide_minimult: true*/},
  { title: 'Поведение',                component: 'PeakScales',               permission: 'view_testresultsbehaviour',        checkField: 'behaviour' },
  // { title: 'Компетенции',              component: 'Competencies',             permission: 'view_testresultscompetencies',     checkField: 'competences' },
  { title: 'Личностные способности',   component: 'PersonalAbilities',          permission: 'view_testresultscompetencies',     checkField: 'personal_abilities' },
  { title: 'Управленческий потенциал', component: 'ManagementPotential',      permission: 'view_testresultscompetencies',     checkField: 'management_potential' },  /*TODO management_potential*/
  { title: 'Стрессоустойчивость',      component: 'StressTolerance',          permission: 'view_testresultsstressresistance', checkField: 'stress_tolerance' },
  { title: 'Тревожность',              component: 'Anxiety',                  permission: 'view_testresultsstressresistance', checkField: 'anxiety' },
  { title: 'Мотиваторы и Демотиваторы', component: 'MotivatorsAndDestructors', permission: 'view_testresultsmotivatorsanddestructors', checkField: 'motivators_and_destructors', /*hide_minimult: true */},
  { title: 'Роли в команде',           component: 'TeamRoles',                permission: 'view_testresultsteamroles',        checkField: 'team_roles' },
  { title: 'Стиль коммуникации',       component: 'ManagementStyle',          permission: 'view_testresultsmanagementstyle',  checkField: 'leadership_styles' },
  { title: 'Логика',                   component: 'Logic',                    permission: 'view_testresultslogic',            checkField: 'logic_tests' },
  { title: 'Карта интересов',          component: 'ProfInterests',            permission: 'view_testresultsinterestsmap',     checkField: 'prof_interests' },
  
  { title: 'Профнавигатор',            component: 'TestEditorResult',         permission: 'view_testresultscompliencereport', checkField: 'professiograms' },
]

const initialState = () => ({
  tabs: [],
  selectedTab: 0,
  reportTabs: [],
});

export default {
  namespaced: true,
  state: initialState,
  getters: {
    allCheckBoxes: () => allTabs,
    tabs: (state) => state.tabs,
    selectedTab: (state) => state.selectedTab,
    reportTabs: (state) => state.reportTabs,
  },

  mutations: {
    setReportTabs(state, tabs) {
      state.reportTabs = tabs;
    },
    setTabs(state, tabs) {
      state.tabs = tabs;
    },
    setSelectedTab(state, tab) {
      state.selectedTab = tab;
    },
    resetState(state) {
      const initial = initialState();
      Object.keys(initial).forEach((key) => { state[key] = initial[key]; });
    },
  },

  actions: {
    setTabs({ commit, rootGetters }, data) {
      const tabs = [];

      function addTab(component) {
        const tab = allTabs.find(t => t.component === component)
        if (tab && rootGetters['auth/isAllowed'](tab.permission) && !(data.is_minimult && tab.hide_minimult))
          tabs.push(tab)
        else
          console.log("tab not allowed", tab)
      }
      function addConstructorTab(keyTestData) {
        const tab = allTabs.find(t => t.component === 'TestEditorResult')
        if (tab && rootGetters['auth/isAllowed'](tab.permission)) {
          const testDesc = data.general_info?.tests.find(el => el.test_code===keyTestData)
          tabs.push({
            ...tab,
            title: testDesc ? testDesc.title : "Профнавигатор",
            keyTestData,
            testDesc,
            testData: data.constructor_test_results[keyTestData]
          });
        } else
          console.log("tab not allowed", tab)
      }

      const user = rootGetters['auth/user']
      // if ( user?.user_type === CANDIDATE && data.general_info)
      //   tabs.push({ title: 'Общая информация', component: 'GeneralInformation' });

      if (data.scales?.scales?.scales && data.scales.scales.scales.filter((s) => s.description).length)
        addTab('Credibility');

      // if (data.compliance)
        addTab('ComplianceReport');

      if (data.attantion_factor || data.risk_factors)
        addTab('Risks');

      if (data.scales && data.scales.scales)
        addTab('Scales');

      if (data.scales && data.scales.peak_scales && data.scales.peak_scales.scales.length)
        addTab('PeakScales');

      if (data.personal_abilities) 
        addTab('PersonalAbilities');

      if (data.management_potential)  
         addTab('ManagementPotential');

      if (data.stress_tolerance)
        addTab('StressTolerance');

      if (data.anxiety)
        addTab('Anxiety');

      if (data.motivators_and_destructors)
        addTab('MotivatorsAndDestructors');

      if (data.team_roles)
        addTab('TeamRoles');

      if (data.leadership_styles)
        addTab('ManagementStyle');

      if (data.logic)
        addTab('Logic');

      if (data.prof_interests)
        addTab('ProfInterests');

      //for (let key in data.constructor_test_results)
      //  addConstructorTab(key)

      commit('setTabs', tabs);
      if (tabs.length) {
        commit('setSelectedTab', tabs[0]);
      }

    },
    setSelectedTab({ commit }, tab) {
      commit('setSelectedTab', tab);
    },
    resetState({ commit }) {
      commit('resetState');
    },
    loadAllReportTabs({ commit, rootGetters }) {
      const tabs = allTabs.filter(t => rootGetters['auth/isAllowed'](t.permission));
      commit('setReportTabs', tabs);
    },

  },
};
