import api from '@/common/api';
import Vue from 'vue';
import * as XLSX from 'xlsx';
import moment from 'moment';
/* eslint-disable */

const initialState = () => ({
  positions: [],
  profs: [],
  chapters: [],
  candidatesCalc: [],
  candidatesResult: [],
  columns: [],
  loading: {
    positions: false,
    profs: false,
    calc: false,
    excel: false,
    loadResults: false
  },
  errors: {
    calc: '',
    loadPositions: [],
  },
  progress: {
    cur: 0,
    total: 0,
  },
  stopCalc: false,

});

const NO_DATA_TEXT = '-'


export default {
  namespaced: true,
  state: initialState,
  actions: {
    async exportToExcelShort({ commit, state, getters, rootGetters }, { candidates, filename }) {
      commit('startLoading', 'excel');

      const headersRow0 = [
        "Фамилия и Имя",
        "Электронная почта",
        'Возраст',
        'Должность',
        ...state.columns.map(el => el.name)
      ];

      const rows = candidates
        // .filter(el => el.compliances)
        .map(item => {
          return [
            item.last_name + ' ' + item.first_name,
            item.email || NO_DATA_TEXT,
            item.age || NO_DATA_TEXT,
            item.position || NO_DATA_TEXT,

            ...state.columns.map(el => item[el.id] ? item[el.id] : 0)
          ];
        });

      const data = [
        headersRow0,
        ...rows,
      ];

      const ws = XLSX.utils.aoa_to_sheet(data);
      const wb = XLSX.utils.book_new();

      ws['!cols'] = [
        { wch: 30 },   // "Фамилия и Имя",
        { wch: 20 },   // "Электронная почта",
        { wch: 10 },   // 'Возраст',
        { wch: 15 },   // 'Должность',
      ].concat(Array(state.columns.length).fill(
        { wch: 25 })
      );

      XLSX.utils.book_append_sheet(wb, ws, 'Кандидаты');
      XLSX.writeFile(wb, (filename ? filename : 'result') + '.xlsx');

      commit('endLoading', 'excel');
    },


    async exportToExcelFull({ commit, state, getters, rootGetters }, { candidates, filename }) {
      const NO_DATA_TEXT = '-'
      const FIXED_COLS_COUNT = 7
      const FIXED_COLS_COUNT_2 = 6
      commit('startLoading', 'excel');

      const mapChapters = new Map()  // Все разделы, которые встречаются у кандидатов. В каждом разделе - Set с ПГ
      for (let cand of candidates) {
        for (let comp of cand.compliances) {
          for (let c of comp.chapters) {
            let setProfs = mapChapters.get(c)
            if (!setProfs) {
              setProfs = new Set()
              mapChapters.set(c, setProfs)
            }
            setProfs.add(comp.professiogram_name ? comp.professiogram_name : '-')
          }
        }
      }

      // console.log(mapChapters)

      const arrProfCols = []   // массив всех ПГ, в порядке соответсвующем разделам
      for (let chapter of mapChapters) {
        for (let col of chapter[1]) {
          arrProfCols.push(col)
        }
      }
      // console.log(arrProfCols)

      let headersRow0 = Array(FIXED_COLS_COUNT).fill('');
      for (let chapter of mapChapters) {
        // chapter: [ "Избранное", Set(19) ]
        headersRow0.push(chapter[0])
        headersRow0 = headersRow0.concat(Array(chapter[1].size-1).fill(''))
      }
      headersRow0 = headersRow0.concat(Array(FIXED_COLS_COUNT_2).fill(''))
      headersRow0.push('Структура интеллекта')
      headersRow0 = headersRow0.concat(Array(2).fill(''))
      headersRow0.push('Профинтресы')
      headersRow0 = headersRow0.concat(Array(2).fill(''))

      let headersRow1 = [
        "Фамилия и Имя",
        "Электронная почта",
        'Возраст',
        'Пол',
        'Дата тестирования',
        'Должность',
        'Оценка',
      ];
      headersRow1 = headersRow1.concat(arrProfCols)
      headersRow1 = headersRow1.concat([
        'Факторы риска',
        'Зоны внимания',
        'Достоверность',
        'Стрессоустойчивость',
        'Стиль коммуникации ведущий',
        'Ведущая роль в команде',
        'Числовой',
        'Вербальный',
        'Невербальный',
        'Интерес 1',
        'Интерес 2',
        'Интерес 3',
      ])

      const rows = candidates
        // .filter(el => el.compliances)
        .map(item => {
          const colsProf = arrProfCols.map(profName => {
            const pg = item.compliances.find(el=> el.professiogram_name===profName)
            return pg ? Math.round(pg.compliance*100) : ''
          })
          return [
            item.last_name + ' ' + item.first_name,
            item.email || NO_DATA_TEXT,
            item.age || NO_DATA_TEXT,
            item.str_gender || NO_DATA_TEXT,
            item.test_date ? moment(item.test_date).format('DD.MM.YYYY') : NO_DATA_TEXT,
            item.position || NO_DATA_TEXT,
            item.compliances ? item.compliances.map(el => el.professiogram_name + ' - ' + Math.round(el.compliance*100)).join('. ') : '',
            ...colsProf,
            item.str_risk_factors || NO_DATA_TEXT,
            item.str_attention_factors || NO_DATA_TEXT,
            item.str_reliability || NO_DATA_TEXT,
            item.str_stress_tolerance || NO_DATA_TEXT,
            item.str_leadership_styles || NO_DATA_TEXT,
            item.team_role || NO_DATA_TEXT,
            item.numeric_logic || NO_DATA_TEXT,
            item.verbal_logic || NO_DATA_TEXT,
            item.non_verbal_logic || NO_DATA_TEXT,
            item.prof_interests[0] ? item.prof_interests[0].title : NO_DATA_TEXT,
            item.prof_interests[1] ? item.prof_interests[1].title : NO_DATA_TEXT,
            item.prof_interests[2] ? item.prof_interests[2].title : NO_DATA_TEXT,

          ];
        });

      const data = [
        headersRow0,
        headersRow1,
        ...rows,
      ];

      const ws = XLSX.utils.aoa_to_sheet(data);

      const merge = [
      ];
      let col = FIXED_COLS_COUNT
      for (let chapter of mapChapters) {
        // { s: { r: 0, c: 9 }, e: { r: 0, c: 21 } },
        // // chapter: [ "Избранное", Set(19) ]
        merge.push({
          s: { r: 0, c: col },
          e: { r: 0, c: col+chapter[1].size-1 }
        })
        col += chapter[1].size
      }
      col += FIXED_COLS_COUNT_2
      merge.push({ s: { r: 0, c: col }, e: { r: 0, c: col + 2} })
      col += 3
      merge.push({ s: { r: 0, c: col }, e: { r: 0, c: col + 2} })

      ws['!merges'] = merge;

      ws['!cols'] = [
          { wch: 30 },   // "Фамилия и Имя",
          { wch: 20 },   // "Электронная почта",
          { wch: 10 },   // 'Возраст',
          { wch: 5 },    // 'Пол',
          { wch: 10 },   // 'Дата тестирования',
          { wch: 15 },   // 'Должность',
          { wch: 20 },   // 'Оценка',
      ].concat(Array(arrProfCols.length).fill(
        { wch: 25 })
      ).concat(Array(FIXED_COLS_COUNT_2).fill(
        { wch: 25 })
      ).concat(Array(3).fill(
        { wch: 15 })
      ).concat(Array(3).fill(
        { wch: 45 })
      );

      // ws['!rows'] = [
      //   {hpx: 16},
      //   {hpx: 32}
      // ]

      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Кандидаты');
      XLSX.writeFile(wb, (filename ? filename : 'result') + '.xlsx');

      commit('endLoading', 'excel');
    },



    async loadResult({ commit, state, getters, rootGetters }, { corp_clients }) {
      commit('startLoading', 'loadResults');
      commit('setCandidatesResult', {candidates: []});

      let response
      try {
        response = await api.post('/professiogram/get-computed-compliances/', { corp_clients });
      } catch (err) {
        console.log(err)
      }

      let arrCandidates = []
      for (let corpClientId in response) {
        arrCandidates = arrCandidates.concat(response[corpClientId].candidates_compliances)
      }

      const candidates = []
      // console.log(arrCandidates)
      for (let candidate of arrCandidates)
      {
        if (!candidate)
          continue;
        // candidate.test_date = t_r.general_info.test_date
        candidate.str_gender = candidate.gender==='Female' ? 'Ж' : 'М'
        candidate.str_chapters = candidate.chapters?.join(', ')
        candidate.company = candidate.corp_client?.company
        candidate.corp_client_id = candidate.corp_client?.id

        // факторы риска (список risk_factors/factors/, поля title)
        candidate.str_risk_factors = candidate.risk_factors?.join(', ')
        // зоны внимания (список attention_factor/factors/, поля title)
        candidate.str_attention_factors = candidate.attention_factors?.join(', ')
        // достоверность (reliability)
        if (candidate.reliability===0) candidate.str_reliability = 'Не достоверный'
        if (candidate.reliability===1) candidate.str_reliability = 'Сомнительно достоверный'
        if (candidate.reliability===2) candidate.str_reliability = 'Достоверный'
        // стрессоустойчивость (stress_tolerance/stress_tolerance_name)
        candidate.str_stress_tolerance = candidate.stress_tolerance?.stress_tolerance_name
        // стиль коммуникации ведущий (leadership_styles/title, имеющий значение HIGH)
        candidate.str_leadership_styles = candidate.leadership_styles?.join(', ')
        candidate.compliances = candidate.compliances ? candidate.compliances.sort((a, b) => b.compliance - a.compliance) : []

        candidate.prof_interests = candidate.prof_interests ? candidate.prof_interests.sort((a, b) => b.points - a.points) : []
        // роль в команде ведущая (с наибольшим числом из team_roles/points, team_roles_normalized/team_roles/title )
        // team_role
        // числовой интеллект (logic/results/value где code=NUMERIC_LOGIC)
        // numeric_logic
        // вербальный (logic/results/value где code=VERBAL_LOGIC)
        // verbal_logic
        // невербальный (logic/results/value где code=NON_VERBAL_LOGIC)
        // non_verbal_logic
        // проф интересы (список интересов и их значений в порядке убывания значений) ( {prof_interests/title, prof_interests/points} )
        // информация о корп клиенте кандидата (id, компания)
        candidates.push(candidate)
      }

      commit('setCandidatesResult', {candidates});
      commit('endLoading', 'loadResults');
    },

    async calc({ commit, state, getters, rootGetters }, {payload, calcBlockSize}) {

      const idsCandidates = payload.candidates
      const allCandidates = rootGetters['users/candidates/candidates']

      commit('stopCalc', false);
      commit('startLoading', 'calc');
      commit('setError', {field: 'calc', error: ''});
      commit('setCandidatesCalc', {candidates: [], columns: []});
      commit('setProgressTotal', idsCandidates.length);

      const CALC_BLOCK_SIZE = calcBlockSize
      let candidates2Calc = []
      let response = []
      for (let cur=0 ; cur<idsCandidates.length ; cur++ ) {
        if (state.stopCalc) break;

        commit('setProgressCur', cur+1);
        candidates2Calc.push(idsCandidates[cur])

        if (candidates2Calc.length < CALC_BLOCK_SIZE && cur !== idsCandidates.length-1)
          continue;

        try {
          payload.candidates = candidates2Calc;
          const responseItem = await api.post('/professiogram/compute-compliances/', payload);
          if (responseItem) {
            const arrCand = responseItem.map(el => el.compliances)
            response = response.concat(...arrCand)
          }
        } catch (err) {
          // commit('setError', {field: 'calc', error: err.data.error});
          console.log(err)
          for (let candErrId of candidates2Calc) {
            const candidateErr = allCandidates.find(el => el.id === candErrId)

            response = response.concat([{
              id: candErrId,
              error: err.data?.error ?? "Ошибка при расчете",
              first_name: candidateErr ? candidateErr.first_name : undefined,
              last_name: candidateErr ? candidateErr.last_name : undefined
            }])
          }
          // break;
        }
        candidates2Calc = []
      }

      const candidates = []
      const setColumns = new Set()
      for (let candidate of response)
      {
        const profsItems = candidate.compliances
        if (profsItems)
          for (let item of profsItems) {
            for (let key in item) {
              const idProf = +key
              const compliance = +item[key]
              if (!isNaN(idProf) && !isNaN(compliance)) {
                setColumns.add(idProf)
                candidate[key] = +compliance
              }
            }
          }
        candidates.push(candidate)
      }
      // console.log('candidates', candidates)

      const columns = []
      setColumns.forEach(id => {
        const prof = state.profs.find(el => el.id===id)
        columns.push(prof);
      })

      commit('setCandidatesCalc', {candidates, columns});
      commit('endLoading', 'calc');
      commit('stopCalc', false);
    },

    async loadProfs({ commit, state, getters }) {
      try {
        commit('startLoading', 'profs');
        const profs = await api.get('/professiogram/all/');
        profs.sort( (a, b) => a.name > b.name )
        commit('setProfs', profs);
        const chapters = await api.get('/professiogram/chapter/');
        chapters.forEach(el => {
          Vue.set(el, 'children', getters['chapterProfs'](el.id))
        })
        commit('setChapters', chapters);
      } catch (err) {
        console.log(err)
        // commit('loadFailure', Object.entries(err.data));
      }
      commit('endLoading', 'profs');
    },

    async loadPositions({ commit }) {
      commit('startLoading', 'positions');
      try {
        const response = await api.get('/users/positions/');
        response.sort( (a, b) => a.title > b.title )
        commit('setPositions', response);
      } catch (err) {
        console.log(err)
      }
      commit('endLoading', 'positions');
    },

    resetState({ commit }) {
      commit('resetState');
    },
  },
  mutations: {
    setPositions(state, positions) {
      state.positions = positions;
    },
    setProfs(state, items) {
      state.profs = items;
    },
    setChapters(state, chapters) {
      state.chapters = chapters
    },
    setCandidatesResult(state, {candidates}) {
      state.candidatesResult = candidates
    },
    setCandidatesCalc(state, {candidates, columns}) {
      state.candidatesCalc = candidates
      state.columns = columns
    },
    setProgressTotal(state, total) {
      state.progress.total = total
    },
    setProgressCur(state, total) {
      state.progress.cur = total
    },
    resetState(state) {
      const initial = initialState();
      Object.keys(initial).forEach((key) => { state[key] = initial[key]; });
    },
    startLoading(state, field) {
      state.loading[field] = true;
    },
    setError(state, {field, error}) {
      state.errors[field] = error;
    },
    endLoading(state, field) {
      state.loading[field] = false;
    },
    stopCalc(state, stop) {
      state.stopCalc = stop;
    },

  },
  getters: {
    positions: (state) => state.positions,
    profs: (state) => state.profs,
    chapters: (state) => state.chapters,
    chapterProfs: (state) => (chapterId) => state.profs.filter((el) => el.chapters.find((ch) => ch.id==chapterId)),
    loading: (state) => state.loading,
    errors: (state) => state.errors,
    candidatesCalc: (state) => state.candidatesCalc,
    candidatesResult: (state) => state.candidatesResult,
    columns: (state) => state.columns,
    progress: (state) => state.progress,
  },
};
