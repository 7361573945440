<template>
  <v-app>
    <router-view />
  </v-app>
</template>

<script>
export default {
  name: 'AuthLayout',
};
</script>

<style scoped>

</style>
