export const EMAIL = {
  MAX_LENGTH: 50,
};
export const PASSWORD = {
  MIN_LENGTH: 8,
  MAX_LENGTH: 40,
};
export const FIRST_NAME = {
  MAX_LENGTH: 20,
};
export const LAST_NAME = {
  MAX_LENGTH: 20,
};
export const COMPANY = {
  MAX_LENGTH: 30,
};

export const CITY = {
  MAX_LENGTH: 20,
};
export const TAXPAYER_ID_NUMBER = {
  MIN_LENGTH: 12,
  MAX_LENGTH: 12,
};
