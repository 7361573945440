import api from '@/common/api';
import Vue from 'vue';
/* eslint-disable */
import { cloneDeep } from 'lodash';

const initialState = () => ({
  loading: {
    positions: false,
    profs: false,
    stock_balance: false,
    candidates_count: false,
    number_of_not_endowed_candidatesMMPI: false,
    number_of_not_endowed_candidatesOther: false,
    calc: false,
    loadResults: false,
    calcList: false,
  },
  stock_balance: 0,
  analData: {
    candidates_count: 0,
    number_of_not_endowed_candidatesMMPI: 0,
    number_of_not_endowed_candidatesOther: 0,
  },
  positions: [],
  profs: [],
  chapters: [],
  calcs: [],
  totalCalcs: 0,
  progress: {
    cur: 0,
    total: 0,
  },
  stopCalc: false,
  candidatesCalc: [],
  compliance_computation_setups: [],
  candidatesResult: [],
  columns: [],
  errors: {
    calc: '',
  },

});

export default {
  namespaced: true,
  state: cloneDeep(initialState()),
  actions: {

    async loadBalance({ commit, rootGetters, dispatch }) {
      commit('startLoading', 'stock_balance');
      commit('startLoading', 'candidates_count');
      commit('startLoading', 'number_of_not_endowed_candidatesMMPI');
      commit('startLoading', 'number_of_not_endowed_candidatesOther');

      try {
        let managers = rootGetters['users/managers/managers'];
        if (!managers || !managers.length) {
          await dispatch('users/managers/loadManagers', {}, { root: true });
          managers = rootGetters['users/managers/managers'];
        }

        const user = rootGetters['auth/user'];
        let balance = user ? user.licenses_available : 0;
        // console.log(user, user.licenses_available, balance)
        commit('setStockBalance', balance);

        const candidates_count = managers.reduce((acc, el) => acc + el.candidates_count, 0);
        commit('setAnalData', { key: 'candidates_count', value: candidates_count });

        function getTestValue(tests, name) {
          const test = tests?.find(el => el.name===name);
          return test ? test.number_of_not_endowed_candidates : 0
        }

        const cnt1 = managers.reduce((acc, el) => {
          return acc + getTestValue(el.tests, 'MMPI')
        }, 0);
        commit('setAnalData', { key: 'number_of_not_endowed_candidatesMMPI', value: cnt1 });

        const cnt2 = managers.reduce((acc, el) => {
          return acc
            + getTestValue(el.tests, 'NUMERIC_LOGIC')
            + getTestValue(el.tests, 'VERBAL_LOGIC')
            + getTestValue(el.tests, 'NON_VERBAL_LOGIC')
            + getTestValue(el.tests, 'PROF_INTERESTS')
        }, 0);
        commit('setAnalData', { key: 'number_of_not_endowed_candidatesOther', value: cnt2 });

      } catch (err) {
        console.log('!ERROR', err);
      } finally {
        commit('endLoading', 'stock_balance');
        commit('endLoading', 'candidates_count');
        commit('endLoading', 'number_of_not_endowed_candidatesMMPI');
        commit('endLoading', 'number_of_not_endowed_candidatesOther');
      }
    },

    async loadProfs({ commit, state, getters }) {
      try {
        commit('startLoading', 'profs');
        const profs = await api.get('/professiogram/all/');
        profs.sort( (a, b) => a.name > b.name )
        commit('setProfs', profs);
        const chapters = await api.get('/professiogram/chapter/');
        chapters.forEach(el => {
          Vue.set(el, 'children', getters['chapterProfs'](el.id))
        })
        chapters.forEach(el => {
          el.id = -el.id
        })
        commit('setChapters', chapters);
      } catch (err) {
        console.log(err)
        // commit('loadFailure', Object.entries(err.data));
      }
      commit('endLoading', 'profs');
    },

    async loadPositions({ commit }) {
      commit('startLoading', 'positions');
      try {
        const response = await api.get('/users/positions/');
        response.sort( (a, b) => a.title > b.title )
        commit('setPositions', response);
      } catch (err) {
        console.log(err)
      }
      commit('endLoading', 'positions');
    },

    async calc({ commit, state, getters, rootGetters }, {payload, calcBlockSize}) {

      const idsCandidates = payload.candidates
      const allCandidates = rootGetters['users/candidates/candidates']

      commit('stopCalc', false);
      commit('startLoading', 'calc');
      commit('setError', {field: 'calc', error: ''});
      commit('setCandidatesCalc', {candidates: [], columns: []});
      commit('setProgressTotal', idsCandidates.length);

      const CALC_BLOCK_SIZE = calcBlockSize
      let candidates2Calc = []
      let arrCalcIds = []
      for (let cur=0 ; cur<idsCandidates.length ; cur++ ) {
        if (state.stopCalc) break;

        commit('setProgressCur', cur+1);
        candidates2Calc.push(idsCandidates[cur])

        if (candidates2Calc.length < CALC_BLOCK_SIZE && cur !== idsCandidates.length-1)
          continue;

        try {
          payload.candidates = candidates2Calc;
          const responseItem = await api.post('/professiogram/compute-compliances/', payload);
          if (responseItem) {
            const arrIds = responseItem.map(el => el.setup_id)
            console.log(arrIds)
            arrCalcIds = arrCalcIds.concat(...arrIds)
          }
        } catch (err) {
          // commit('setError', {field: 'calc', error: err.data.error});
          console.log(err)
          // break;
        }
        candidates2Calc = []
      }

      console.log(arrCalcIds);
      commit('setComplianceComputationSetups', arrCalcIds);
      commit('endLoading', 'calc');
      commit('stopCalc', false);
    },


    async loadResult({ commit, state, getters, rootGetters }) {
      commit('startLoading', 'loadResults');
      commit('setCandidatesResult', {candidates: []});

      let response
      try {
        response = await api.post('/professiogram/get-computed-compliances/', { compliance_computation_setups: state.compliance_computation_setups });
      } catch (err) {
        console.log(err)
      }

      let arrCandidates = []
      for (let corpClientId in response) {
        arrCandidates = arrCandidates.concat(response[corpClientId].candidates_compliances)
      }

      const candidates = []
      // console.log(arrCandidates)
      for (let candidate of arrCandidates)
      {
        if (!candidate)
          continue;
        // candidate.test_date = t_r.general_info.test_date
        candidate.str_gender = candidate.gender==='Female' ? 'Ж' : 'М'
        candidate.str_chapters = candidate.chapters?.join(', ')
        candidate.company = candidate.corp_client?.company
        candidate.corp_client_id = candidate.corp_client?.id

        // факторы риска (список risk_factors/factors/, поля title)
        candidate.str_risk_factors = candidate.risk_factors?.join(', ')
        // зоны внимания (список attention_factor/factors/, поля title)
        candidate.str_attention_factors = candidate.attention_factors?.join(', ')
        // достоверность (reliability)
        if (candidate.reliability===0) candidate.str_reliability = 'Не достоверный'
        if (candidate.reliability===1) candidate.str_reliability = 'Сомнительно достоверный'
        if (candidate.reliability===2) candidate.str_reliability = 'Достоверный'
        // стрессоустойчивость (stress_tolerance/stress_tolerance_name)
        candidate.str_stress_tolerance = candidate.stress_tolerance?.stress_tolerance_name
        // стиль коммуникации ведущий (leadership_styles/title, имеющий значение HIGH)
        candidate.str_leadership_styles = candidate.leadership_styles?.join(', ')
        candidate.compliances = candidate.compliances ? candidate.compliances.sort((a, b) => b.compliance - a.compliance) : []

        candidate.prof_interests = candidate.prof_interests ? candidate.prof_interests.sort((a, b) => b.points - a.points) : []
        // роль в команде ведущая (с наибольшим числом из team_roles/points, team_roles_normalized/team_roles/title )
        // team_role
        // числовой интеллект (logic/results/value где code=NUMERIC_LOGIC)
        // numeric_logic
        // вербальный (logic/results/value где code=VERBAL_LOGIC)
        // verbal_logic
        // невербальный (logic/results/value где code=NON_VERBAL_LOGIC)
        // non_verbal_logic
        // проф интересы (список интересов и их значений в порядке убывания значений) ( {prof_interests/title, prof_interests/points} )
        // информация о корп клиенте кандидата (id, компания)
        candidates.push(candidate)
      }

      commit('setCandidatesResult', {candidates});
      commit('endLoading', 'loadResults');
    },

    async loadCalcList({ commit, state, getters, rootGetters }, pageOptions) {
      commit('startLoading', 'calcList');
      const params = {
        page: pageOptions ? pageOptions.page : 1,
        page_size: pageOptions ? pageOptions.itemsPerPage : 10,
        ordering: '-created_at'
      }

      try {
        const response = await api.get('/professiogram/compliance-computation-setup/', { params } )
        commit('setCalcList', response);
      } catch (err) {
        // commit('setError', {field: 'calc', error: err.data.error});
        console.log(err)
        // break;
      }
      commit('endLoading', 'calcList');
    },

  },
  mutations: {
    startLoading(state, field) {
      state.loading[field] = true;
    },
    endLoading(state, field) {
      state.loading[field] = false;
    },
    setStockBalance(state, data) {
      state.stock_balance = data;
    },
    setAnalData(state, { key, value }) {
      state.analData[key] = value;
    },
    reset(state) {
      Object.assign(state, cloneDeep(initialState()));
    },
    setPositions(state, positions) {
      state.positions = positions;
    },
    setProfs(state, items) {
      state.profs = items;
    },
    setChapters(state, chapters) {
      state.chapters = chapters
    },
    setProgressTotal(state, total) {
      state.progress.total = total
    },
    setProgressCur(state, total) {
      state.progress.cur = total
    },
    stopCalc(state, stop) {
      state.stopCalc = stop;
      console.log(stop)
    },
    setError(state, {field, error}) {
      state.errors[field] = error;
    },
    setCandidatesCalc(state, {candidates, columns}) {
      state.candidatesCalc = candidates
      state.columns = columns
    },
    setCandidatesResult(state, {candidates}) {
      state.candidatesResult = candidates
    },
    setCalcList(state, result) {

      state.calcs = result.results
      state.totalCalcs = result.count
    },
    setComplianceComputationSetups(state, compliance_computation_setups) {
      state.compliance_computation_setups = compliance_computation_setups
    }
  },
  getters: {
    loading: (state) => state.loading,
    stock_balance: (state) => state.stock_balance,
    analData: (state) => state.analData,
    positions: (state) => state.positions,
    profs: (state) => state.profs,
    chapters: (state) => state.chapters,
    chapterProfs: (state) => (chapterId) => state.profs.filter((el) => el.chapters.find((ch) => ch.id==chapterId)),

    errors: (state) => state.errors,
    candidatesCalc: (state) => state.candidatesCalc,
    candidatesResult: (state) => state.candidatesResult,
    columns: (state) => state.columns,
    progress: (state) => state.progress,
    calcs: (state) => state.calcs,
    totalCalcs: (state) => state.totalCalcs,
  },
};
