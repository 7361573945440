import testing from './testing';
import session from './session';

export default {
  namespaced: true,
  modules: {
    testing,
    session,
  },
};
