import api from '@/common/api';

const initialState = () => ({
  positions: [],
  loading: {
    loadPositions: false,
  },
  errors: {
    loadPositions: [],
  },
});

export default {
  namespaced: true,
  state: initialState,
  actions: {
    async loadPositions({ commit }) {
      commit('loadPositionsRequest');
      try {
        const response = await api.get('/users/candidates/positions/');
        commit('loadPositionsSuccess', response);
        return response;
      } catch (err) {
        commit('loadPositionsFailure', Object.entries(err.data));
        throw err;
      }
    },
    resetState({ commit }) {
      commit('resetState');
    },
  },
  mutations: {
    loadPositionsRequest(state) {
      state.loading.loadPositions = true;
      state.errors.loadPositions = [];
    },
    loadPositionsSuccess(state, positions) {
      state.positions = positions;
      state.loading.loadPositions = false;
    },
    loadPositionsFailure(state, error) {
      state.errors.loadPositions = error;
      state.loading.loadPositions = false;
    },
    resetState(state) {
      const initial = initialState();
      Object.keys(initial).forEach((key) => { state[key] = initial[key]; });
    },
  },
  getters: {
    positions: (state) => [
      { text: 'Без должности', value: '' },
      ...state.positions.map((p) => ({ text: p, value: p })),
    ],
    loading: (state) => state.loading,
    errors: (state) => state.errors,
  },
};
