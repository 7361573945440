<template>
  <v-dialog v-model="isDialogVisible" max-width="1250px" :fullscreen="isFullScreen" >
    <v-card class="_pa-sm-8" ref="refDialog">
      <v-toolbar dark color="primary">
        <v-toolbar-title>Расчет соответствия</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn icon dark @click="isFullScreen = !isFullScreen"><v-icon>mdi-window-maximize</v-icon></v-btn>
          <v-btn icon dark @click="isDialogVisible = false"><v-icon>mdi-close</v-icon></v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <div v-if="loading.positions || loading.profs || loading.loadResults" class="h-100 my-7 d-flex justify-center align-center">
        <v-progress-circular :size="25" color="primary" indeterminate />
      </div>
      <div v-else class="mx-4" ref="setupCard">
          <div class="mt-3 d-flex">
            <v-spacer />
            <v-btn _v-if="$store.getters['auth/isAllowed']('download_testresultscompliencereport')"
              class="_w-100 mb-3" color="primary" :disabled="!candidates2show.length" :loading="loading.excel" @click="toExcel()">
              Выгрузка в Excel (полная)
            </v-btn>
          </div>
          <!-- <div class="d-flex align-center">
            <div class="mr-1 d-none d-sm-block">Показывать:</div>
            <v-select v-model="selectedHeaders" :items="headers2select"
              multiple outlined dense hide-details style="width: 420px; max-width:80vw;" @change="changeSelectedHeaders"
            >
              <template v-slot:selection="{ item, index }">
                <v-chip v-if="index < 2" small><span>{{ item.text ? item.text : item.text2 }}</span></v-chip>
                <span v-if="index === 2" class="grey--text caption">(+еще {{ selectedHeaders.length - 2 }})</span>
              </template>
              <template v-slot:item="{ active, item, attrs }">
                <div class="d-flex align-center w-100">
                  <div><v-checkbox hide-details class="ma-0 mb-1" :input-value="(attrs.inputValue)"></v-checkbox></div>
                  <div class="d-flex align-center  justify-space-between w-100">
                    <div>{{item.text ? item.text : item.text2}}</div>
                  </div>
                </div>
              </template>
            </v-select>
          </div> -->

      </div>

      <v-data-table
        :headers="candidates2show.length ? allHeaders : []"
        :items="candidates2show"
        show-select v-model="candidatesForExport"
        class="px-0 _px-sm-5 elevation-1"
        sort-by="id" sort-desc must-sort
        :loading="loading.loadingResult" loading-text="Идет загрузка... Пожалуйста подождите"
        :mobile-breakpoint="300" :items-per-page="-1" :height="tableHeight" fixed-header hide-default-footer
      >
        <template v-slot:item.fullname="{ item }">
          <div class="d-flex _justify-space-between align-center">

            <v-tooltip bottom v-if="item.error" color="error">
              <template v-slot:activator="{ on, attrs }">
                <v-icon color="error" dark v-bind="attrs" v-on="on" class="mr-2">mdi-alert-circle-outline</v-icon>
              </template>
              <span>{{item.error}}</span>
            </v-tooltip>

            <router-link class="text-primary mr-2" :to="{ name: 'candidates-id', params: { id: item.id } }">
              <template v-if="item.last_name || item.first_name">{{ item.last_name }} {{ item.first_name }}</template>
              <template v-else>{{ item.id }}</template>
            </router-link>
          </div>
        </template>
        <!-- <template v-slot:item.email="{ item }">{{item.email}}</template> -->
        <template v-slot:item.test_date="{ item }">
          {{ $moment(item.test_date).format('DD.MM.YYYY') }}
        </template>

        <template v-slot:item.compliances="{ item }">
            <div class="">
                <span v-if="!item.compliances || !item.compliances.length">  </span>
                <v-menu v-else offset-y :close-on-content-click="false" ref="menuCompliance">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn elevation="0" class="comp-width-col font-xs" v-bind="attrs" v-on="on">
                      <div class="comp-table-btn">
                        <div>{{item.compliances[0].professiogram_name}}</div>
                        <div class="d-flex">
                          <div class="comp-circle">{{Math.round(item.compliances[0].compliance*100)}} </div>
                          <v-icon v-if="attrs['aria-expanded']">mdi-chevron-down</v-icon><v-icon v-else>mdi-chevron-up</v-icon>
                        </div>
                      </div>
                    </v-btn>
                  </template>

                  <v-list>
                    <v-list-item v-for="(comp, i) in item.compliances.slice(0, 10)" :key="i">
                      <v-list-item-content>
                        <v-list-item-title class="font-sm">{{ comp.professiogram_name }}</v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-action class="comp-circle"> {{Math.round(comp.compliance*100)}} </v-list-item-action>
                    </v-list-item>
                  </v-list>
                </v-menu>
            </div>
        </template>

        <template v-slot:item.prof_interests="{ item }">
            <div class="">
                <span v-if="!item.prof_interests || !item.prof_interests.length">  </span>
                <v-menu v-else offset-y :close-on-content-click="false" ref="menuProfInterests">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn elevation="0" class="comp-width-col font-xs" v-bind="attrs" v-on="on">
                      <div class="comp-table-btn interest">
                        <div>{{item.prof_interests[0].title}}</div>
                        <div class="d-flex">
                          <div class="comp-circle interest">{{item.prof_interests[0].points}} </div>
                          <v-icon v-if="attrs['aria-expanded']">mdi-chevron-down</v-icon><v-icon v-else>mdi-chevron-up</v-icon>
                        </div>
                      </div>
                    </v-btn>
                  </template>

                  <v-list>
                    <v-list-item v-for="(comp, i) in item.prof_interests.slice(0, 10)" :key="i">
                      <v-list-item-content>
                        <v-list-item-title class="font-sm">{{ comp.title }}</v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-action class="comp-circle interest"> {{comp.points}} </v-list-item-action>
                    </v-list-item>
                  </v-list>
                </v-menu>
            </div>
        </template>

        <template v-slot:no-data> Кандидатов не найдено. </template>
      </v-data-table>

    </v-card>
  </v-dialog>

</template>

<script>
/* eslint-disable */
import { mapActions, mapGetters, mapMutations } from 'vuex';

export default {
  name: 'CalcSetupCompliance',
  components: {
  },
  model: {
    prop: 'value',
    event: 'input',
  },
  props: {
    value: Boolean,
    clients: Array
  },
  data: () => ({
    tableHeight: 20,
    isFullScreen: false,
    candidatesForExport: [],
    allHeaders: [
      { text: 'id', value: 'id', sortable: false},
      { text: 'Фамилия и Имя', value: 'fullname', width: '280px', sortable: false},
      { text: 'Электронная почта', value: 'email', sortable: false},
      { text: 'Возраст', value: 'age', sortable: false},
      { text: 'Пол', value: 'str_gender', sortable: false},
      { text: 'Дата тестирования', value: 'test_date', sortable: false},
      { text: 'Должность', value: 'position', sortable: false},
      { text: 'Название раздела', value: 'str_chapters', sortable: false},
      { text: '% соответствия', value: 'compliances', sortable: false},
      { text: 'Факторы риска', value: 'str_risk_factors', sortable: false},
      { text: 'Зоны внимания', value: 'str_attention_factors', sortable: false},
      { text: 'Достоверность', value: 'str_reliability', sortable: false},
      { text: 'Cтрессоустойчивость', value: 'str_stress_tolerance', sortable: false},
      { text: 'Ведущий стиль коммуникации', value: 'str_leadership_styles', sortable: false},
      { text: 'Ведущая роль в команде', value: 'team_role', sortable: false},
      { text: 'Числовой интеллект', value: 'numeric_logic', sortable: false},
      { text: 'Вербальный интеллект', value: 'verbal_logic', sortable: false},
      { text: 'Невербальный интеллект', value: 'non_verbal_logic', sortable: false},
      { text: 'Интересы', value: 'prof_interests', sortable: false},
      { text: 'Компания', value: 'company', width: '150px',sortable: false},
      { text: 'Id клиента', value: 'corp_client_id', sortable: false},

    ],
    selectedHeaders: [],

  }),
  watch: {
    async value(val) {
      if (val) {
        this.setTableHeight();
        if (this.clients && this.clients.length)
          await this.loadResult({ corp_clients: this.clients })
      }
    },
    isFullScreen(val) {
      setTimeout(()=>{
        this.setTableHeight();
      }, 400)
    },
  },
  computed: {
    ...mapGetters({
      profs: 'profgrams/bulkcalc/profs',
      loading: 'profgrams/bulkcalc/loading',
      errors: 'profgrams/bulkcalc/errors',
      candidates: 'profgrams/bulkcalc/candidatesResult',
      user: 'auth/user',
    }),
    isDialogVisible: {
      get() {
        return this.value;
      },
      set(value) {
        if (!value) {
          this.$emit('input', false);
        }
      },
    },
    candidates2show() {
      return this.candidates // .filter(el => el.compliances);
    },
    headers2select() {
      return [...this.allHeaders];
    },
    showHeaders() {
      return this.allHeaders.filter(s => this.selectedHeaders.includes(s.value));
    },
  },
  methods: {
    ...mapActions({
      exportToExcelFull: 'profgrams/bulkcalc/exportToExcelFull',
      loadResult: 'profgrams/bulkcalc/loadResult',
    }),
    setTableHeight() {
        if (this.isFullScreen) {
          const setupCard = this.$refs.setupCard
          const height = document.documentElement.clientHeight - 75 - setupCard.clientHeight;
          this.tableHeight = Math.max(height, 400);
        }
        else
          this.tableHeight = 400;
    },
    changeSelectedHeaders() {
      localStorage.setItem('showCandidateColumnsCompliance', JSON.stringify(this.selectedHeaders));
    },

    async toExcel() {
      await this.exportToExcelFull({ candidates: this.candidatesForExport.length ? this.candidatesForExport : this.candidates2show})
    }

  },
  async mounted() {
    this.selectedHeaders = JSON.parse(localStorage.getItem('showCandidateColumnsCompliance'));
    if (!this.selectedHeaders) {
      this.selectedHeaders = this.allHeaders.map(s => s.value);
    }
  }
};
</script>

<style scoped lang="scss">

.comp-circle {
  flex-shrink: 0;
  font-size: 11px;
  border-radius: 50%;
  background-color: #ff700a;
  color: white;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 2px 10px !important;
  padding: 0 2px 0 0;
}

.comp-circle.interest {
  background-color: #007ef3;
}

.comp-table-btn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 200px;
}

.comp-table-btn.interest {
  width: 300px;
}
</style>


