import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#18A0FB',
        secondary: '#18A0FB',
        accent: '#8c9eff',
        warning: '#EFAC00',
        error: '#FF6E6D',
        success: '#94D727',
      },
    },
  },
});
