/* eslint-disable */
// import { TEST_TYPES, TEST_TYPES_TITLES } from '@/common/constants/testTypes';
import api from '@/common/api';
import Vue from 'vue';
import { cloneDeep } from 'lodash';

// {
//     id:
//     title: 
//     isStd: 
// }


const initialState = () => ({
    stdTests: [
        { id: 'MMPI', title: 'ММИЛ', isStd: true },
        { id: 'VERBAL_LOGIC', title: 'Вербальная логика', isStd: true },
        { id: 'NON_VERBAL_LOGIC', title: 'Невербальная логика', isStd: true },
        { id: 'NUMERIC_LOGIC', title: 'Числовая логика', isStd: true },
        { id: 'PROF_INTERESTS', title: 'Проф интересы', isStd: true },
        { id: 'MINIMULT', title: 'Минимульт', isStd: true },

    ],
    userTests: [],
    loading: {
        tests: false,
    }
});

export default {
  namespaced: true,
  state: initialState,
  actions: {
    async loadUserTests({ state, commit, }) {
      commit('startLoading', 'userTests');
      try {
      } catch (err) {
        console.log("ERROR!!!", err)
      } finally {
        commit('endLoading', 'userTests');
      }

    },

  },
  mutations: {
    startLoading(state, field) {
        state.loading[field] = true;
    },
    endLoading(state, field) {
        state.loading[field] = false;
    },
    setUserTests(state, userTests) {
        state.userTests = userTests;
    },
  },
  getters: {
    loading: (state) => state.loading,
    allTests: (state) => [...state.stdTests, ...state.userTests],
    stdTests: (state) => state.stdTests,
    getTestTitle: (state) => (test) => test.title,
    allowedTests: (state, getters) => getters.allTests.filter(el => getters.isTestAllowed(el)),
    allowedStdTests: (state, getters) => state.stdTests.filter(el => getters.isTestAllowed(el)),
    isTestAllowed: (state, getters, rootState, rootGetters) => (test) => {
        if (!test.isStd) {
          return getters.isTestCodeAllowed(test.code)
        }
        return getters.isTestCodeAllowed(test.id)
    },
    isTestCodeAllowed: (state, getters, rootState, rootGetters) => (testCode) => {
      if (testCode === 'MMPI') return rootGetters['auth/isAllowed']('can_send_mill');
      if (testCode === 'VERBAL_LOGIC') return rootGetters['auth/isAllowed']('can_send_verb_logic');
      if (testCode === 'NON_VERBAL_LOGIC') return rootGetters['auth/isAllowed']('can_send_nonverb_logic');
      if (testCode === 'PROF_INTERESTS') return rootGetters['auth/isAllowed']('can_send_prof_interests');
      if (testCode === 'NUMERIC_LOGIC') return rootGetters['auth/isAllowed']('can_send_numerical_test');
      if (testCode === 'MINIMULT') return rootGetters['auth/isAllowed']('can_send_minimult');
      // права на отчет из конструктора
      return rootGetters['auth/isAllowed'](`can_send_${testCode}`) || rootGetters['auth/isAllowed'](`can_send_${testCode.toLowerCase()}`)
    },

    isReportAllowed: (state, getters, rootState, rootGetters) => (testCode) => {
      // права на отчет из конструктора
      return rootGetters['auth/isAllowed'](`view_report_${testCode}`) || rootGetters['auth/isAllowed'](`view_report_${testCode.toLowerCase()}`) ||
          rootGetters['auth/isAllowed'](`view_report_with_recommendations_${testCode}`) || rootGetters['auth/isAllowed'](`view_report_with_recommendations_${testCode.toLowerCase()}`) ||
          rootGetters['auth/isAllowed'](`can_send_${testCode}`) || rootGetters['auth/isAllowed'](`can_send_${testCode.toLowerCase()}`)
    },
    isReportDownloadAllowed: (state, getters, rootState, rootGetters) => (testCode) => {
      // права на отчет из конструктора
      return rootGetters['auth/isAllowed'](`download_report_${testCode}`) || rootGetters['auth/isAllowed'](`download_report_${testCode.toLowerCase()}`) ||
          rootGetters['auth/isAllowed'](`download_report_with_recommendations_${testCode}`) || rootGetters['auth/isAllowed'](`download_report_with_recommendations_${testCode.toLowerCase()}`) ||
          rootGetters['auth/isAllowed'](`can_send_${testCode}`) || rootGetters['auth/isAllowed'](`can_send_${testCode.toLowerCase()}`)
    },
    
  },
};


// view_report_${testCode}  - право на просмотр отчета без рекомендациями



  