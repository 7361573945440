import api from '@/common/api';

const initialState = () => ({
  loading: {
    createSession: false,
  },
  errors: {
    createSession: [],
  },

});

export default {
  namespaced: true,
  state: initialState,
  actions: {
    async createTestingSession({ commit }, { tests, candidate }) {
      const data = {
        tests,
        candidate,
      };
      commit('createTestingSessionRequest');
      try {
        const response = await api.post('/users/candidates/create-testing-session/', data);
        commit('createTestingSessionSuccess');
        return { candidate, response };
      } catch (err) {
        commit('createTestingSessionFailure', Object.entries(err.data));
        throw err;
      }
    },
  },
  mutations: {
    createTestingSessionRequest(state) {
      state.loading.createSession = true;
      state.errors.createSession = [];
    },
    createTestingSessionSuccess(state) {
      state.loading.createSession = false;
    },
    createTestingSessionFailure(state, error) {
      state.errors.createSession = error;
      state.loading.createSession = false;
    },
  },
  getters: {
    loading: (state) => state.loading,
    errors: (state) => state.errors,
  },
};
