<template>
  <div class="auth-card large text-sm-center">
    <h2 class="auth-card__title mb-8">Расскажите о себе</h2>

    <form @submit.prevent="submit">
      <div class="row">
        <div class="col-md-6">
          <v-text-field label="Фамилия *"
                        v-model="form.last_name"
                        @input="$v.form.last_name.$touch()"
                        @blur="$v.form.last_name.$touch()"
                        :hide-details="!lastnameErrors.length"
                        :error-messages="lastnameErrors"
                        outlined />
        </div>
        <div class="col-md-6">
          <v-text-field label="Имя *"
                        v-model="form.first_name"
                        @input="$v.form.first_name.$touch()"
                        @blur="$v.form.first_name.$touch()"
                        :hide-details="!firstnameErrors.length"
                        :error-messages="firstnameErrors"
                        outlined />
        </div>

        <div class="col-md-6">
          <v-text-field label="Номер телефона *"
                        type="tel"
                        v-model="form.phone"
                        @input="$v.form.phone.$touch()"
                        @blur="$v.form.phone.$touch()"
                        :hide-details="!phoneErrors.length"
                        :error-messages="phoneErrors"
                        v-mask="'+7-(###)-###-##-##'"
                        outlined />
        </div>
        <div class="col-md-6 _pt-3 _pb-6">
          <!-- <v-text-field
                _type="date"
                v-model="form.date_of_birth"
                label="Дата рождения *"
                _append-icon="mdi-calendar"
                _readonly
                format="DD.MM.YYYY"
                @change="$v.form.date_of_birth.$touch()"
                @blur="$v.form.date_of_birth.$touch()"
                :hide-details="!birthdateErrors.length"
                :error-messages="birthdateErrors"
                outlined
              >
              <template v-slot:append>
                <v-menu ref="menu" v-model="menu"
                :close-on-content-click="false"
               transition="scale-transition" offset-y min-width="auto">

               <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" text ><v-icon>mdi-calendar</v-icon></v-btn>
                </template>

                <v-date-picker v-model="form.date_of_birth"
                           _no-title scrollable locale="ru-RU"
                           @input="inputDate" first-day-of-week=1>
            </v-date-picker>
          </v-menu>
              </template>

              </v-text-field>

          {{form.date_of_birth}} -->
<!--
          <date-picker
        v-model="form.date_of_birth"
        format="DD.MM.YYYY"
        type="date"
        placeholder="Дата рождения *"
      ></date-picker> -->

          <v-menu ref="menu" v-model="menu"
                  :close-on-content-click="false"
                  transition="scale-transition" offset-y min-width="auto">
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                :value="birthdateFormatted"
                label="Дата рождения *"
                append-icon="mdi-calendar"
                readonly
                format="DD.MM.YYYY"
                @change="$v.form.date_of_birth.$touch()"
                @blur="$v.form.date_of_birth.$touch()"
                :hide-details="!birthdateErrors.length"
                :error-messages="birthdateErrors"
                v-bind="attrs"
                v-on="on"
                outlined />
            </template>
            <v-date-picker v-model="form.date_of_birth"
                          :active-picker.sync="activePicker"
                           _no-title scrollable locale="ru-RU"
                           @input="menu = false" first-day-of-week=1>
            </v-date-picker>
          </v-menu>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <v-text-field label="Город *"
                        v-model="form.city"
                        @input="$v.form.city.$touch()"
                        @blur="$v.form.city.$touch()"
                        :hide-details="!cityErrors.length"
                        :error-messages="cityErrors"
                        outlined />
        </div>
        <div class="col-md-6">
          <v-text-field label="Должность"
                        v-model="form.position"
                        :hide-details="!positionErrors.length"
                        :error-messages="positionErrors"
                        @input="$v.form.position.$touch()"
                        @blur="$v.form.position.$touch()"
                        outlined />
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <v-combobox
            label="Образование"
            v-model="form.education" :items="arrEducation"
            :hide-details="!educationErrors.length"
            :error-messages="educationErrors"
            :return-object="false" outlined>
          </v-combobox>
        </div>
        <div class="col-md-6">
          <v-combobox
            label="Уровень должности"
            v-model="form.position_level" :items="arrPositionLevel"
            :hide-details="!positionLevelErrors.length"
            :error-messages="positionLevelErrors"
            :return-object="false" outlined>
          </v-combobox>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <div class="text-left" :class="{'error--text':genderErrors.length}">Пол *</div>
          <v-radio-group v-model="form.gender" row
                         @change="$v.form.gender.$touch()"
                         :hide-details="!genderErrors.length"
                         :error-messages="genderErrors">
            <v-radio label="Мужской" :value="GENDERS.MALE" />
            <v-radio label="Женский" :value="GENDERS.FEMALE" />
          </v-radio-group>
        </div>
      </div>

      <div v-for="(err, ind) in errors" :key="ind" class="error--text">
        <template v-if="!form.hasOwnProperty(err[0])">
          <div v-for="(e, i) in err[1]" :key="i">{{e}}</div>
        </template>
      </div>

      <div class="row mt-6">
        <div class="col-md-6 mx-auto">
          <v-btn type="submit" class="w-100" x-large color="primary">Продолжить</v-btn>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { maxLength, required } from 'vuelidate/lib/validators';
import { VueMaskDirective } from 'v-mask';
import { GENDERS } from '@/common/constants/genders';
import { MESSAGES } from '@/common/constants/errorMessages';
import { CITY, FIRST_NAME, LAST_NAME } from '@/common/constants/validations';
import { firstnameValidation, lastnameValidation, phoneValidation } from '@/common/validationHelpers';
// import DatePicker from 'vue2-datepicker';
// import 'vue2-datepicker/index.css';

export default {
  name: 'PersonalInformation',
  components: {
    // DatePicker,
  },
  directives: {
    mask: VueMaskDirective,
  },
  validations: {
    form: {
      last_name: { required, lastnameValidation, maxLength: maxLength(LAST_NAME.MAX_LENGTH) },
      first_name: { required, firstnameValidation, maxLength: maxLength(FIRST_NAME.MAX_LENGTH) },
      phone: { required, phoneValidation },
      date_of_birth: { required },
      city: { required, maxLength: maxLength(CITY.MAX_LENGTH) },
      gender: { required },
      position: { required },
      position_level: { required },
      education: { required },
    },
  },
  data: () => ({
    GENDERS,
    menu: false,
    activePicker: null,
    form: {
      last_name: '',
      first_name: '',
      phone: '',
      date_of_birth: '',
      city: '',
      position: '',
      education: '',
      position_level: '',
      gender: '',
    },
    arrEducation: [
      'Среднее', 'Среднее-специальное', 'Неоконченное высшее', 'Высшее', 'Ученая степень'
    ],
    arrPositionLevel: [
      'Стажер', 'Линейный сотрудник', 'Специалист', 'Руководитель среднего звена', 'Руководитель высшего звена', 'Собственник бизнеса'
    ]
  }),
  computed: {
    ...mapGetters({
      errors: 'testing/testing/errors',
      candidate: 'testing/testing/candidate',
      loading: 'testing/testing/loading',
      positions: 'positions/positions/positions',
      loadingPositions: 'positions/positions/loading',
    }),
    lastnameErrors() {
      const errors = [];
      if (!this.$v.form.last_name.$dirty) return errors;
      if (!this.$v.form.last_name.required) errors.push(MESSAGES.REQUIRED);
      if (!this.$v.form.last_name.lastnameValidation) errors.push(MESSAGES.INCORRECT_LASTNAME);
      if (!this.$v.form.last_name.maxLength) errors.push(MESSAGES.MAX_LENGTH(LAST_NAME.MAX_LENGTH));

      return this.errors.find((err) => err[0] === 'last_name')?.[1] ?? errors;
    },
    firstnameErrors() {
      const errors = [];
      if (!this.$v.form.first_name.$dirty) return errors;
      if (!this.$v.form.first_name.required) errors.push(MESSAGES.REQUIRED);
      if (!this.$v.form.first_name.firstnameValidation) errors.push(MESSAGES.INCORRECT_FIRSTNAME);
      if (!this.$v.form.first_name.maxLength) errors.push(MESSAGES.MAX_LENGTH(FIRST_NAME.MAX_LENGTH));

      return this.errors.find((err) => err[0] === 'first_name')?.[1] ?? errors;
    },
    phoneErrors() {
      const errors = [];
      if (!this.$v.form.phone.$dirty) return errors;
      if (!this.$v.form.phone.required) errors.push(MESSAGES.REQUIRED);
      if (!this.$v.form.phone.phoneValidation) errors.push(MESSAGES.INCORRECT_PHONE);

      return this.errors.find((err) => err[0] === 'phone')?.[1] ?? errors;
    },
    birthdateFormatted() {
      return this.form.date_of_birth ? this.$moment(this.form.date_of_birth).format('DD.MM.YYYY') : '';
    },
    birthdateErrors() {
      const errors = [];
      if (!this.$v.form.date_of_birth.$dirty) return errors;
      if (!this.$v.form.date_of_birth.required) errors.push(MESSAGES.REQUIRED);

      return this.errors.find((err) => err[0] === 'date_of_birth')?.[1] ?? errors;
    },
    cityErrors() {
      const errors = [];
      if (!this.$v.form.city.$dirty) return errors;
      if (!this.$v.form.city.required) errors.push(MESSAGES.REQUIRED);
      if (!this.$v.form.city.maxLength) errors.push(MESSAGES.MAX_LENGTH(CITY.MAX_LENGTH));

      return this.errors.find((err) => err[0] === 'city')?.[1] ?? errors;
    },
    genderErrors() {
      const errors = [];
      if (!this.$v.form.gender.$dirty) return errors;
      if (!this.$v.form.gender.required) errors.push(MESSAGES.REQUIRED);

      return this.errors.find((err) => err[0] === 'gender')?.[1] ?? errors;
    },
    positionErrors() {
      const errors = [];
      if (!this.$v.form.position.$dirty) return errors;
      if (!this.$v.form.position.required) errors.push(MESSAGES.REQUIRED);
      return this.errors.find((err) => err[0] === 'position')?.[1] ?? errors;
    },
    positionLevelErrors() {
      const errors = [];
      if (!this.$v.form.position_level.$dirty) return errors;
      if (!this.$v.form.position_level.required) errors.push(MESSAGES.REQUIRED);
      return this.errors.find((err) => err[0] === 'position_level')?.[1] ?? errors;
    },
    educationErrors() {
      const errors = [];
      if (!this.$v.form.education.$dirty) return errors;
      if (!this.$v.form.education.required) errors.push(MESSAGES.REQUIRED);
      return this.errors.find((err) => err[0] === 'education')?.[1] ?? errors;
    },
  },
  watch: {
    menu(val) {
      if (val) {
        setTimeout(() => {
          this.activePicker = 'YEAR';
        });
      }
    },
  },
  methods: {
    ...mapActions({
      updatePersonalData: 'testing/testing/updatePersonalData',
      loadPositions: 'positions/positions/loadPositions',
    }),
    inputDate() {
      this.menu = false;
    },
    submit() {
      if (this.loading) return;
      this.$v.$touch();
      if (!this.$v.$invalid) {
        const form = {
          ...this.form,
          position: this.form.position,
          phone: this.form.phone.match(/\d+/g).join([]),
        };
        this.updatePersonalData({ form });
      }
    },
  },
  created() {
    this.form.last_name = this.candidate.last_name ?? '';
    this.form.first_name = this.candidate.first_name ?? '';
    this.form.phone = this.candidate.phone ?? '';
    this.form.date_of_birth = this.candidate.date_of_birth ?? '';
    this.form.city = this.candidate.city ?? '';
    this.form.position = this.candidate.position ?? '';
    this.form.gender = this.candidate.gender ?? '';
    this.loadPositions();
  },
};
</script>
