<template>
  <div class="mmpi">
    <h2 class="question__title mb-8 " v-html="question"></h2>
    <div>
      <div class="row">
        <div class="col-md-6 py-2" v-for="answer in testData.answer_options" :key="answer.id">
          <v-btn class="w-100 justify-start test-btn" :class="{'is-delay': isDelay}"
                 @click="onClickAnswer(answer)"
                 :elevation="0"
                 :disabled="loading || isDelay"
                 :color="(selectedAnswer && selectedAnswer.id === answer.id) ? 'primary' : ''"
                 :outlined="selectedAnswer && selectedAnswer.id === answer.id"
                 large>

            {{answer.title}}
          </v-btn>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'MMPI',
  data() {
    return {
      isDelay: false,
      timerId: 0
    }
  },
  computed: {
    ...mapGetters({
      testData: 'testing/testing/testData',
      loading: 'testing/testing/loading',
      selectedAnswer: 'testing/testing/selectedAnswer',
      currentStep: 'testing/testing/currentStep',
    }),
    question() {
      return `<span>${this.testData.question.question}<span>`;
    },
  },
  methods: {
    ...mapActions({
      sendAnswer: 'testing/testing/sendAnswer',
      setSelectedAnswer: 'testing/testing/setSelectedAnswer',
    }),
    async onClickAnswer(answer) {
      await this.setSelectedAnswer(answer);
      this.isDelay = true;
      await this.sendAnswer();
      if (this.timerId) {
        clearTimeout(this.timerId)
      }
      this.timerId = setTimeout(() => {
        this.isDelay = false;
      }, 800)

    },
  },
  beforeDestroy() {
    if (this.timerId) {
      clearTimeout(this.timerId)
    }
  }
};
</script>
<style scoped lang="scss">
.mmpi {
  min-height: 10rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.test-btn {
  transition: opacity 0.4s ease;
}
.is-delay {
  opacity: 0.2;
}


</style>
