<template>
  <div class="header">
    <div class="row">
      <div class="col-12">
        <div>
          <router-link :to="{name: 'home'}"><img src="@/assets/images/logo.svg" alt="Главная"></router-link>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div v-if="back">
          <a @click="$router.go(-1)" class="d-flex align-center">
            <img class="mr-3" src="@/assets/images/icons/arrow-long.svg" alt="Назад"> Назад
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HeaderAuth',
  props: {
    back: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped lang="scss">
.header {
  padding: 2rem;
}
</style>
